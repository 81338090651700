* {
    margin: 0;
    padding: 0;
}
.image-container {
    /* width: 500px; */
    overflow: hidden;
    position: relative;
    /* scale: 0.4; */
    zoom: 0.15;
    display: inline-block;
    /* height: fit-content; */
    /* overflow: scroll; */
}

/* 

.image-container {
    position: relative;
    overflow: scroll;
    height: 100%;
    width: 100%;
    display: inline-block;
    
}

*/
.modal {
    position: absolute;
    display: grid;
    gap: 0.5rem;
    align-content: baseline;
    align-items: center;
    z-index: 10;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
    opacity: 0.9;
    /* gap: 1rem; */
    border: 2px solid black;
    background: rgb(238, 238, 238);

    padding: 4px;
    margin: 4px;
    box-shadow: rgb(208, 165, 165) 3px 3px 5px;
    border-radius: 6px;
    opacity: 0.9;
    display: block;
    position: absolute;
    width: fit-content;
    z-index: 9999;
    /* cursor: not-allowed; */
}
.container-parent {
    position: relative;
}
.heading {
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-size: 11px;
}
.modal h3 {
    font-size: 11px;
}
.data-wrapper {
    width: fit-content;
}
.modal .data h3,
.modal .data p {
    font-size: 9px;
}
table {
    border-collapse: collapse;
}
table tr td {
    padding: 1px;
    font-size: 10px;
    text-align: center;
}
table tr td {
    border-bottom: 1px solid lightgray;
    padding: 3px 5px;
}
.status {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Count_table {
    padding: 1rem;
    display: grid;
    gap: 0.5rem;
}

.Count_table > h3 {
    font-weight: 600;
    color: brown;
}
.table_data_wrapper {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
}
.table_data {
    color: white;
    width: 100px;
    text-align: center;

    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    font-weight: 500;
    display: grid;
    gap: 0.2rem;
}
.table_data h3 {
    font-weight: 500;
    font-size: 13px;
}
.table_data p {
    font-size: 13px;
}
